<template>
    <!--编辑页与详情页相同-->

    <div class="RefundInDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker v-model="form.createTime" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="操作人">
                            <el-input v-model="form.updater" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="操作日期">
                            <el-date-picker v-model="form.updateTime" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="销售机构">
                            <el-input v-model="form.repoName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="进货机构">
                            <el-input v-model="form.deptName" style="width: 220px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="状态">
                            <el-input v-model="form.stockStatus" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                v-loading="tableLoading"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left">
                    <!--                    type='selection'复选框 -->
                </el-table-column>
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>

                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="80" />
                <el-table-column label="包装因子" width="100" v-if="bigUnitFlag">
                    <template slot-scope="scope">
                        {{ scope.row.packFactor }}
                    </template>
                </el-table-column>
                <el-table-column label="退货价" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.wholeSalePrice | m }}
                    </template>
                </el-table-column>
                <el-table-column label="退货箱数" width="100" prop="bigUnitCounts" v-if="bigUnitFlag">
                    <template slot-scope="scope">
                        <span>{{ refund(scope.row) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="散装数量" width="100" prop="bulkCounts" v-if="bigUnitFlag" />
                <el-table-column label="退货总数" width="100" prop="counts" />
                <el-table-column label="退货金额" width="100" prop="refundMoney" />
                <el-table-column label="赠品数量" width="100" prop="giftCounts" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import MoneyUtils from 'js/MoneyUtils';
import decimal from 'decimal';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'RefundInDetail',
    props: ['form'],
    data() {
        return {
            url: {
                queryGoods: '/goods/goods/list_c',
            },
            meta: {
                types: [],
            },
            codes: [],
            tableData: [],
            bigUnitFlag: false,
            tableLoading: true,
        };
    },
    mounted() {
        const _this = this;
        _this.form.stockStatus == 0 ? (_this.form.stockStatus = '待入库') : (this.form.stockStatus = '已入库');
        this.getUnitFlag(this.form.deptCode);
    },
    methods: {
        getUnitFlag(code) {
            UrlUtils.HasBigUnitPrivilege(this, code, (flag) => {
                this.bigUnitFlag = flag;
            });
            this.handleQuery();
        },
        refund(row) {
            // eslint-disable-next-line new-cap
            const dec = new decimal(row.counts).sub(row.bulkCounts).div(row.packFactor).toString();
            const num = parseInt(dec);
            if (isNaN(num)) {
                return '';
            } else {
                row.bigUnitCounts = num;
            }
            return row.bigUnitCounts;
        },
        handleQuery() {
            const _this = this;
            UrlUtils.QueryGoodsInfoByCodes(_this, _this.url.queryGoods, { codes: _this.form.goodsCode }, (data) => {
                data.forEach((v, i) => {
                    v.packFactor = this.form.packFactors[i];
                    const dbPrice = this.form.prices[i];
                    v.wholeSalePrice = MoneyUtils.moneyToYuan(dbPrice);
                    v.bulkCounts = this.form.bulkCounts[i];
                    v.counts = this.form.counts[i];
                    v.giftCounts = this.form.giftCounts[i];
                    v.refundMoney = MoneyUtils.moneyToYuan(
                        this.decimal(dbPrice || 0)
                            .mul(v.counts || 0)
                            .toNumber()
                    );
                });
                _this.tableData = data;
            });
        },
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
        money(counts, prices) {
            if (typeof counts == 'undefined') {
                return '';
            } else if (typeof prices == 'undefined') {
                return '';
            } else {
                return (counts * prices).toFixed(2);
            }
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
